import { Button } from '../button/button';
import imageInfo from '../../assets/images/price-info.png';
import qr from '../../assets/images/qr.png';
import price from '../../assets/images/price.svg';
import './price.scss';
import { RequestModal } from '../requestModal/requestModal';
import { useState } from 'react';
import { createPortal } from 'react-dom';

export const Price = () => {
    const [activeModal, setActiveModal] = useState(false);

    const onOpenModal = () => {
        setActiveModal(true);
    };

    const onCloseModal = () => {
        setActiveModal(false);
    };

    if (activeModal) {
        document.body.style.overflow = 'hidden';
    } else {
        document.body.style.overflow = 'unset';
    }

    return (
        <section className='price'>
            <div className='__wrapper'>
                <div className='price__wrapper price__content'>
                    <div className='price__header'>
                        <h1 className='price__header-title'>
                            Какова стоимость привлечения гостя в лояльность?
                        </h1>
                        <Button theme='green' onClick={onOpenModal}>
                            Рассчитать стоимость проекта
                        </Button>
                    </div>

                    <div className='price__info'>
                        <img
                            src={imageInfo}
                            alt='Дополнительная информация'
                            className='price__info-image'
                        />
                        <span>
                            За 2 месяца до открытия нового ресторана в Тольятти
                            собрали в лояльность 3 500 гостей, чем обеспечили
                            полную вечернюю посадку с первых дней работы!
                        </span>
                    </div>

                    <div className='price__qr'>
                        Точкой входа является листовка с QR-кодом
                        <img src={qr} alt='qr-код' />
                    </div>

                    <img
                        src={price}
                        alt='Расчеты стоимости гостя'
                        className='price__image'
                    />
                </div>
            </div>
            {createPortal(
                <RequestModal active={activeModal} onClose={onCloseModal} />,
                document.body
            )}
        </section>
    );
};
