import { Button } from '../button/button';
import iconTelegram from '../../assets/images/footer-telegram.svg';
import useMediaQuery from '@mui/material/useMediaQuery';
import './tariffs.scss';
import { useState } from 'react';
import { createPortal } from 'react-dom';
import { RequestModal } from '../requestModal/requestModal';
import { Contacts } from './Contacts';

export const Tariffs = () => {
    const isMobile = useMediaQuery('(max-width:800px)');
    const [activeModal, setActiveModal] = useState(false);

    const onOpenModal = () => {
        setActiveModal(true);
    };

    const onCloseModal = () => {
        setActiveModal(false);
    };

    if (activeModal) {
        document.body.style.overflow = 'hidden';
    } else {
        document.body.style.overflow = 'unset';
    }

    return (
        <section className='tariffs'>
            <div className='__wrapper'>
                <div className='tariffs__wrapper tariffs__content'>
                    <div>
                        <h1 className='tariffs__header'>Сколько стоит?</h1>

                        <ul className='tariffs__cards'>
                            <li className='tariffs__card'>
                                <h2 className='tariffs__card-title'>
                                    Цена за один ресторан
                                </h2>
                                <span className='tariffs__card-subtitle'>
                                    7 тыс. руб./мес.
                                </span>
                                <Button
                                    theme='transparent-green'
                                    onClick={onOpenModal}
                                    className='tariffs__card-button'>
                                    Подключить
                                </Button>
                            </li>
                            <li className='tariffs__card'>
                                <h2 className='tariffs__card-title'>
                                    От 4х ресторанов
                                </h2>
                                <span className='tariffs__card-subtitle'>
                                    Уменьшаем стоимость для каждого ресторана
                                </span>
                                <Button
                                    theme='transparent-green'
                                    onClick={onOpenModal}
                                    className='tariffs__card-button'>
                                    Подключить
                                </Button>
                            </li>
                            <li className='tariffs__card'>
                                <h2 className='tariffs__card-title'>
                                    При оплате от 3х месяцев
                                </h2>
                                <span className='tariffs__card-subtitle'>
                                    Подключение бесплатно
                                </span>
                                <Button
                                    theme='transparent-green'
                                    onClick={onOpenModal}
                                    className='tariffs__card-button'>
                                    Подключить
                                </Button>
                            </li>
                        </ul>

                        <span className='tariffs__grey-text'>
                            Верификация телефона гостя при регистрации:
                        </span>
                        <span className='tariffs__black-text'>
                            Сервисный звонок от <b>30 копеек</b> или sms
                            сообщение
                            <b> ~2,8 руб.</b>
                        </span>
                    </div>

                    <footer className='tariffs__footer'>
                        <div>
                            <span className='tariffs__footer-text'>
                                Оставьте заявку на подключение
                            </span>
                            {!isMobile && (
                                <span className='tariffs__footer-text tariffs__footer-phone'>
                                    +79835750001
                                </span>
                            )}
                            {isMobile && (
                                <a href='tel:+79835750001'>
                                    <span className='tariffs__footer-text tariffs__footer-phone'>
                                        +79835750001
                                    </span>
                                </a>
                            )}
                            {!isMobile && (
                                <>
                                    <a
                                        href='https://t.me/efood_vladimir'
                                        rel='noreferrer'
                                        target='_blank'>
                                        <img
                                            src={iconTelegram}
                                            alt='Иконка Телеграм'
                                        />
                                    </a>
                                    <span className='tariffs__footer-signature'>
                                        Проект компании{' '}
                                        <a
                                            href='https://evosoft.dev/'
                                            rel='noreferrer'
                                            target='_blank'>
                                            evosoft
                                        </a>
                                    </span>
                                </>
                            )}
                        </div>

                        <Contacts onOpenModal={onOpenModal} />

                        {isMobile && (
                            <>
                                <a
                                    href='https://t.me/efood_vladimir'
                                    rel='noreferrer'
                                    target='_blank'>
                                    <img
                                        src={iconTelegram}
                                        alt='Иконка Телеграм'
                                        className='tariffs__footer-icon'
                                    />
                                </a>
                                <span className='tariffs__footer-signature'>
                                    Проект компании{' '}
                                    <a
                                        href='https://evosoft.dev/'
                                        rel='noreferrer'
                                        target='_blank'>
                                        evosoft
                                    </a>
                                </span>
                            </>
                        )}
                    </footer>
                </div>
            </div>
            {createPortal(
                <RequestModal active={activeModal} onClose={onCloseModal} />,
                document.body
            )}
        </section>
    );
};
