import image1 from '../../assets/images/clients/image1.png';
import image2 from '../../assets/images/clients/image2.png';
import image3 from '../../assets/images/clients/image3.png';
import image4 from '../../assets/images/clients/image4.png';
import image5 from '../../assets/images/clients/image5.png';
import image6 from '../../assets/images/clients/image6.png';
import image7 from '../../assets/images/clients/image7.png';
import image8 from '../../assets/images/clients/image8.png';

import smallImage1 from '../../assets/images/clients/small-image1.png';
import smallImage2 from '../../assets/images/clients/small-image2.png';
import smallImage3 from '../../assets/images/clients/small-image3.png';
import smallImage4 from '../../assets/images/clients/small-image4.png';
import smallImage5 from '../../assets/images/clients/small-image5.png';
import smallImage6 from '../../assets/images/clients/small-image6.png';
import smallImage7 from '../../assets/images/clients/small-image7.png';
import smallImage8 from '../../assets/images/clients/small-image8.png';

export const clientsIcons = [
    { image: image1, alt: 'kannam chicken' },
    { image: image2, alt: 'silver spoon group' },
    { image: image3, alt: 'kannam asia' },
    { image: image4, alt: '0.75 please' },
    { image: image5, alt: 'friends orchestra' },
    { image: image6, alt: 'томат и чеддер' },
    { image: image7, alt: 'шматик' },
    { image: image8, alt: 'угольки и мясо' },
];

export const smallClientsIcons = [
    { image: smallImage8, alt: '0.75 please' },
    { image: smallImage7, alt: 'friends orchestra' },
    { image: smallImage3, alt: 'томат и чеддер' },
    { image: smallImage5, alt: 'kannam chicken' },
    { image: smallImage6, alt: 'silver spoon group' },
    { image: smallImage4, alt: 'kannam asia' },
    { image: smallImage2, alt: 'шматик' },
    { image: smallImage1, alt: 'угольки и мясо' },
];
