import { FC, MouseEvent } from 'react';
import clsx from 'clsx';
import './button.scss';

interface IButtonProps {
    /** Тема кнопки. */
    theme: 'transparent-green' | 'green';

    /** Текст кнопки. */
    children: string;

    /** Иконка перед текстом. */
    icon?: string;

    /** Показатель отключения кнопки. */
    disabled?: boolean;

    /** Тип кнопки. */
    type?: 'button' | 'submit' | 'reset';

    /** Дополнительные стили. */
    className?: string;

    /** Действие при нажатии на кнопку. */
    onClick?(e: MouseEvent<HTMLButtonElement>): void;
}

export const Button: FC<IButtonProps> = (props) => {
    const { theme, children, icon, disabled, className, type, onClick } = props;

    return (
        <button
            className={clsx('button', `button--${theme}`, className)}
            type={type}
            disabled={disabled}
            onClick={onClick}>
            {icon && (
                <img src={icon} alt='Иконка кнопки' className='button__image' />
            )}
            <div className='button__text'>{children}</div>
        </button>
    );
};
