import { FC, ReactNode } from 'react';
import './card.scss';
import clsx from 'clsx';

interface ICardProps {
    children: ReactNode;
    className?: string;
}

export const Card: FC<ICardProps> = ({ children, className }) => {
    return <div className={clsx('card', className)}>{children}</div>;
};
