import { createPortal } from 'react-dom';
import imageInfo from '../../assets/images/price-info.png';
import imageLaptop from '../../assets/images/waiters.png';
import { Button } from '../button/button';
import './waiters.scss';
import { RequestModal } from '../requestModal/requestModal';
import { useState } from 'react';

export const Waiters = () => {
    const [activeModal, setActiveModal] = useState(false);

    const onOpenModal = () => {
        setActiveModal(true);
    };

    const onCloseModal = () => {
        setActiveModal(false);
    };

    /*    if (activeModal) {
        document.body.style.overflow = 'hidden';
    } else {
        document.body.style.overflow = 'auto';
    } */

    return (
        <section className='waiters'>
            <div className='__wrapper'>
                <div className='waiters__wrapper waiters__content'>
                    <div className='waiters__header'>
                        <h1 className='waiters__header-title'>
                            Как вы измеряете вовлеченность официантов в работу с
                            лояльностью?
                        </h1>

                        <Button theme='green' onClick={onOpenModal}>
                            Рассчитать стоимость проекта
                        </Button>
                    </div>

                    <div className='waiters__info'>
                        <img
                            src={imageInfo}
                            alt='Дополнительная информация'
                            className='waiters__info-image'
                        />
                        <span>
                            <b>Проникновение</b> системы лояльности напрямую
                            зависит от
                            <b>работы официантов</b>
                            <br />
                            <b>Средний чек</b> гостей в лояльности выше в
                            среднем <b>на 25-35%</b>
                        </span>
                    </div>
                    <img
                        src={imageLaptop}
                        alt='Экран ноутбука'
                        className='waiters__image'
                    />
                </div>
            </div>
            {createPortal(
                <RequestModal active={activeModal} onClose={onCloseModal} />,
                document.body
            )}
        </section>
    );
};
