import { FC, ReactNode, useState } from 'react';
import { Button } from '../button/button';
//@ts-ignore
import { scroller } from 'react-scroll';
import icon from '../../assets/images/icon-hamburger-menu.svg';
import iconTelegram from '../../assets/images/telegram-hamburger-menu.svg';
import logo from '../../assets/images/logo.svg';
import './hamburgerMenu.scss';
import clsx from 'clsx';
import { RequestModal } from '../requestModal/requestModal';
import { createPortal } from 'react-dom';

interface IHamburgerMenuProps {
    /** Элементы меню. */
    children: ReactNode;
}

export const HamburgerMenu: FC<IHamburgerMenuProps> = ({ children }) => {
    const [activeMenu, setActiveMenu] = useState(false);
    const [activeModal, setActiveModal] = useState(false);

    const onCloseMenu = () => {
        setActiveMenu(false);
    };

    const toggleCloseMenu = () => {
        setActiveMenu((activeMenu) => !activeMenu);
    };

    const onOpenModal = () => {
        onCloseMenu();
        setActiveModal(true);
    };

    const onCloseModal = () => {
        setActiveModal(false);
    };

    const navigate = (section: string) => {
        onCloseMenu();
        scroller.scrollTo(section, {
            delay: 0,
            smooth: 'linear',
        });
    };

    if (activeModal || activeMenu) {
        document.body.style.overflow = 'hidden';
    } else {
        document.body.style.overflow = 'unset';
    }

    return (
        <div id='menuToggle' className={clsx(!activeMenu ? 'close' : 'open')}>
            <input type='checkbox' onClick={toggleCloseMenu} />

            <span className='one'></span>
            <span className='two'></span>
            <span className='three'></span>

            <div id='menu'>
                <div className='menu__header'>
                    <img
                        src={icon}
                        alt='Иконнка компании'
                        className='menu-header image'
                    />
                    <Button
                        theme='transparent-green'
                        className='menu-header button'
                        onClick={onOpenModal}>
                        Обсудить проект
                    </Button>
                </div>

                <ul>
                    <a onClick={() => navigate('#1')}>
                        <img
                            src={logo}
                            alt='Логотип'
                            className='header__image'
                        />
                    </a>
                    <nav className='header__menu menu'>
                        <ul className='menu__list'>
                            <li
                                className='menu__item'
                                onClick={() => navigate('#2')}>
                                <a
                                    href='#2'
                                    className='menu__link'
                                    onClick={onCloseMenu}>
                                    Мы делаем
                                </a>
                            </li>
                            <li
                                className='menu__item'
                                onClick={() => navigate('#8')}>
                                <a
                                    href='#8'
                                    className='menu__link'
                                    onClick={onCloseMenu}>
                                    Тарифы
                                </a>
                            </li>
                            <li
                                className='menu__item'
                                onClick={() => navigate('#4')}>
                                <a
                                    href='#4'
                                    className='menu__link'
                                    onClick={onCloseMenu}>
                                    Кейсы
                                </a>
                            </li>
                        </ul>
                    </nav>
                </ul>
                <a href='#' className='menu__bottom' onClick={onCloseMenu}>
                    <img src={iconTelegram} alt='Ссылка на телеграм' />
                </a>
            </div>
            {createPortal(
                <RequestModal active={activeModal} onClose={onCloseModal} />,
                document.body
            )}
        </div>
    );
};
