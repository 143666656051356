import { Dispatch, FC, SetStateAction } from 'react';

import './Contacts.scss';
import photo from './ContactsPhoto.jpg';
import { Button } from '../../button/button';
import useMediaQuery from '@mui/material/useMediaQuery';

interface ISiteContact {
    name: string,
    role: string,
    contacts: string[]
}

const siteContact: ISiteContact = {
    name: 'Денис Якунин',
    role: 'Коммерческий директор',
    contacts: [
        'https://t.me/dvjakunin',
        'sales@evosoft.dev',
    ],
};

interface Props {
    onOpenModal: () => void;
}

export const Contacts: FC<Props> = ({ onOpenModal }) => {

    const isMobile = useMediaQuery('(max-width:800px)');
    const isEmail = (url: string) => url.includes('@');

    const imageSize = !isMobile ? 200 : 88;

    return (
            <section className={'contacts'}>

              <span className={'contactsPhoto'}>
                  <img
                          alt={siteContact.name}
                          height={imageSize}
                          src={photo}
                          width={imageSize}
                  />
              </span>

                <div className={'contactsText'}>
                    <span className={'contactsName'}>
                        {siteContact.name}
                    </span>
                    <span className={'contactsRole'}>
                        {siteContact.role}
                    </span>
                    <span className={'contactsContacts'}>
                        {
                            siteContact.contacts.map(contact => {
                                return (
                                        <a
                                                href={`${isEmail(contact) ? 'mailto:' : ''}${contact}`}
                                                key={contact.toLowerCase()}
                                                rel="noreferrer"
                                                target="_blank"
                                        >
                                            {contact}
                                        </a>
                                );
                            })
                        }
                  </span>
                    <Button
                            theme="green"
                            onClick={onOpenModal}
                            className="tariffs__manager-button">
                        {!isMobile
                                ? 'Получить консультацию'
                                : 'Подключить'}
                    </Button>
                </div>
            </section>
    );
};

export default Contacts;
