import imageInfo from '../../assets/images/connection-info.png';
import imagePhone1 from '../../assets/images/connection-phone1.png';
import imagePhone2 from '../../assets/images/connection-phone2.png';
import arrow from '../../assets/images/connection-arrow.svg';
import { Button } from '../button/button';
import './connection.scss';
import { useState } from 'react';
import { createPortal } from 'react-dom';
import { RequestModal } from '../requestModal/requestModal';

export const Connection = () => {
    const [activeModal, setActiveModal] = useState(false);

    const onOpenModal = () => {
        setActiveModal(true);
    };

    const onCloseModal = () => {
        setActiveModal(false);
    };

    if (activeModal) {
        document.body.style.overflow = 'hidden';
    } else {
        document.body.style.overflow = 'unset';
    }

    return (
        <section className='connection'>
            <div className='__wrapper'>
                <div className='connection__wrapper connection__content'>
                    <div className='connection__header'>
                        <h1 className='connection__header-title'>
                            Как гость может связаться с рестораном?
                        </h1>

                        <Button theme='green' onClick={onOpenModal}>
                            Рассчитать стоимость проекта
                        </Button>
                    </div>

                    <div className='connection__info'>
                        <img
                            src={imageInfo}
                            alt='Дополнительная информация'
                            className='connection__info-image'
                        />
                        <span>
                            <b>83% гостей</b> предпочитают переписку телефонному
                            звонку
                            <br />
                            Ускоряет обработку обращений гостей <b>в 3-5 раз</b>
                        </span>
                    </div>

                    <div className='connection__image'>
                        <img
                            src={imagePhone1}
                            alt='Чат в телеграм'
                            className='connection__image-one'
                        />
                        <div className='connection__image-wrapper-arrow'>
                            <img
                                src={arrow}
                                alt='Стрелка вправо'
                                className='connection__image-arrow'
                            />
                        </div>
                        <img
                            src={imagePhone2}
                            alt='Чат в приложении'
                            className='connection__image-two'
                        />
                    </div>

                    <ul className='connection__cards'>
                        <li>
                            <h2 className='connection__cards-title'>01</h2>
                            <span className='connection__cards-text'>
                                Достаточно просто написать в чат
                            </span>
                        </li>
                        <li>
                            <h2 className='connection__cards-title'>02</h2>
                            <span className='connection__cards-text'>
                                Сотрудник ресторана отвечает онлайн через свой
                                телефон
                            </span>
                        </li>
                        <li>
                            <h2 className='connection__cards-title'>03</h2>
                            <span className='connection__cards-text'>
                                Есть механизм распределения обращений гостей по
                                сотрудникам
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
            {createPortal(
                <RequestModal active={activeModal} onClose={onCloseModal} />,
                document.body
            )}
        </section>
    );
};
