import { Button } from '../button/button';
import icon from '../../assets/images/main-section-icon.svg';
import images from '../../assets/images/main-section.png';
import './mainSection.scss';
import { Clients } from '../clients/clients';
import { useState } from 'react';
import { RequestModal } from '../requestModal/requestModal';
import { createPortal } from 'react-dom';

export const MainSection = () => {
    const [activeModal, setActiveModal] = useState(false);

    const onOpenModal = () => {
        setActiveModal(true);
    };

    const onCloseModal = () => {
        setActiveModal(false);
    };

    if (activeModal) {
        document.body.style.overflow = 'hidden';
    } else {
        document.body.style.overflow = 'unset';
    }

    return (
        <section className='main-section'>
            <div className='__wrapper'>
                <div className='main-section__wrapper'>
                    <div className='main-section__content content'>
                        <div>
                            <div className='content__product product'>
                                <img
                                    src={icon}
                                    alt='Иконка продукта'
                                    className='product__image'
                                />
                                <span className='product__title'>
                                    Be In Touch
                                </span>
                            </div>

                            <h1 className='content__header'>
                                Помогаем удержать клиента
                            </h1>
                            <h2 className='content__subtitle'>
                                Увеличиваем средний чек!
                            </h2>
                            <Button
                                theme='green'
                                className='content__button'
                                onClick={onOpenModal}>
                                Обсудить проект
                            </Button>
                        </div>

                        <span className='main-section__text'>
                            Нам доверяют своих клиентов
                        </span>
                    </div>

                    <div className='main-section__wrapper-image'>
                        <img
                            src={images}
                            alt='Изображения приложения'
                            className='main-section__image'
                        />
                    </div>
                </div>
                {createPortal(
                    <RequestModal
                        active={activeModal}
                        onClose={onCloseModal}
                    />,
                    document.body
                )}
            </div>
            <Clients />
        </section>
    );
};
