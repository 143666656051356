import groupOfPeople from '../../assets/images/groupOfPeople.svg';
import groupOfPeopleMobile from '../../assets/images/group-of-people-mobile.svg';
import useMediaQuery from '@mui/material/useMediaQuery';
import './reviews.scss';

export const Reviews = () => {
    const isMobile = useMediaQuery('(max-width:800px)');
    return (
        <section className='reviews'>
            <div className='__wrapper'>
                <div className='reviews__wrapper reviews__content'>
                    <h1 className='reviews__header'>
                        Как мы работаем с отзывами гостей
                    </h1>
                    {!isMobile ? (
                        <img
                            src={groupOfPeople}
                            alt='схема работы с отзывами'
                            className='reviews__image'
                        />
                    ) : (
                        <img
                            src={groupOfPeopleMobile}
                            alt='схема работы с отзывами'
                            className='reviews__image'
                        />
                    )}
                </div>
            </div>
        </section>
    );
};
