import { FC } from 'react';
import { FieldProps } from 'formik';
import clsx from 'clsx';
//@ts-ignore
import ReactInputMask from 'react-input-mask';
import person from '../../assets/images/input/person.svg';
import phone from '../../assets/images/input/phone.svg';
import email from '../../assets/images/input/mail.svg';
import './input.scss';

export interface InputProps extends FieldProps {
    icon?: string;
    type?: 'tel';
    className?: string;
}

export const Input: FC<InputProps> = ({
    field,
    form: { touched, errors },
    icon,
    className,
    type,
    ...props
}) => {
    return (
        <div className={clsx('field', className)}>
            {!type && (
                <>
                    <img src={icon} alt='Иконка' className='field__icon' />
                    <input
                        type={type}
                        className={clsx(
                            'field__input',
                            touched[field.name] &&
                                errors[field.name] &&
                                'field__input--error'
                        )}
                        {...field}
                        {...props}
                    />
                </>
            )}

            {type && (
                <>
                    <img
                        src={icon}
                        alt='Иконка телефона'
                        className='field__icon'
                    />
                    <ReactInputMask
                        mask={'+7 (999) 999-9999'}
                        maskChar={null}
                        type={type}
                        className={clsx(
                            'field__input',
                            touched[field.name] &&
                                errors[field.name] &&
                                'field__input--error'
                        )}
                        {...field}
                        {...props}
                    />
                </>
            )}

            {touched[field.name] && errors[field.name] && (
                <span className='field__caption'>
                    {String(errors[field.name])}
                </span>
            )}
        </div>
    );
};
