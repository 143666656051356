import ReactFullpage from '@fullpage/react-fullpage';
import { Header } from '../components/header/header';
import { MainSection } from '../components/mainSection/mainSection';
import { useMediaQuery } from '@mui/material';
import { WeDoSection } from '../components/weDoSection/weDoSection';
import { Price } from '../components/price/price';
import { Mailing } from '../components/mailing/mailing';
import { Connection } from '../components/connection/connection';
import { Reviews } from '../components/reviews/reviews';
import { Waiters } from '../components/waiters/waiters';
import { Tariffs } from '../components/tariffs/tariffs';
import './main.scss';

export const Main = () => {
    const isMobile = useMediaQuery('(max-width:1056px)');

    return (
        <div className='main'>
            {!isMobile && (
                <>
                    <ReactFullpage
                        anchors={['1', '2', '3', '4', '5', '6', '7', '8']}
                        credits={{
                            enabled: true,
                            position: 'right',
                        }}
                        scrollingSpeed={1000}
                        scrollHorizontally={true}
                        navigation
                        render={() => (
                            <ReactFullpage.Wrapper>
                                <div className='section'>
                                    <Header />
                                    <MainSection />
                                </div>
                                <div className='section'>
                                    <Header />
                                    <WeDoSection />
                                </div>
                                <div className='section'>
                                    <Header />
                                    <Price />
                                </div>
                                <div className='section'>
                                    <Header />
                                    <Mailing />
                                </div>
                                <div className='section'>
                                    <Header />
                                    <Connection />
                                </div>
                                <div className='section'>
                                    <Header />
                                    <Reviews />
                                </div>
                                <div className='section'>
                                    <Header />
                                    <Waiters />
                                </div>
                                <div className='section'>
                                    <Tariffs />
                                </div>
                            </ReactFullpage.Wrapper>
                        )}
                    />
                </>
            )}
            {isMobile && (
                <>
                    <Header isFixed />
                    <div id='#1'>
                        <MainSection />
                    </div>
                    <div id='#2'>
                        <WeDoSection />
                    </div>
                    <div id='#3'>
                        <Price />
                    </div>
                    <div id='#4'>
                        <Mailing />
                    </div>
                    <div id='#5'>
                        <Connection />
                    </div>
                    <div id='#6'>
                        <Reviews />
                    </div>
                    <div id='#7'>
                        <Waiters />
                    </div>
                    <div id='#8'>
                        <Tariffs />
                    </div>
                </>
            )}
        </div>
    );
};
