import iconHeader from '../../assets/images/icon-mailing.svg';
import iconPlus from '../../assets/images/icon-plus.svg';
import imageInfo from '../../assets/images/mailing-info.png';
import image1 from '../../assets/images/mailing-image-1.svg';
import image2 from '../../assets/images/mailing-image-2.png';
import { Button } from '../button/button';
import './mailing.scss';
import { useState } from 'react';
import { RequestModal } from '../requestModal/requestModal';
import { createPortal } from 'react-dom';

export const Mailing = () => {
    const [activeModal, setActiveModal] = useState(false);

    const onOpenModal = () => {
        setActiveModal(true);
    };

    const onCloseModal = () => {
        setActiveModal(false);
    };

    if (activeModal) {
        document.body.style.overflow = 'hidden';
    } else {
        document.body.style.overflow = 'unset';
    }

    return (
        <section className='mailing'>
            <div className='__wrapper'>
                <div className='mailing__wrapper mailing__content'>
                    <div className='mailing__header'>
                        <div className='mailing__header-wrapper-title'>
                            <h1 className='mailing__header-title'>
                                Мы отправляем рассылки для ваших гостей
                            </h1>
                            <img src={iconHeader} alt='Иконка письма' />
                        </div>
                        <Button
                            theme='green'
                            icon={iconPlus}
                            onClick={onOpenModal}
                            className='mailing__header-button'>
                            Подключить be in touch
                        </Button>
                    </div>

                    <div className='mailing__info'>
                        <img
                            src={imageInfo}
                            alt='Дополнительная информация'
                            className='mailing__info-image'
                        />
                        <span>
                            Миллион сообщений отправлены за 6 месяцев гостям
                            ресторанной сети в Красноярске. <br /> Конверсия в
                            чек от 5% до 9%
                        </span>
                    </div>

                    <div className='mailing__image'>
                        <img
                            src={image1}
                            alt='Доход от рассылки'
                            className='mailing__image-one'
                        />
                        <img
                            src={image2}
                            alt='Вид рассылки'
                            className='mailing__image-two'
                        />
                    </div>

                    <ul className='mailing__cards'>
                        <li>
                            <h2 className='mailing__cards-title'>01</h2>
                            <span className='mailing__cards-text'>
                                Запускаем расслыку по базе клиента
                            </span>
                        </li>
                        <li>
                            <h2 className='mailing__cards-title'>02</h2>
                            <span className='mailing__cards-text'>
                                Анализируем действия пользователей с рассылкой
                            </span>
                        </li>
                        <li>
                            <h2 className='mailing__cards-title'>03</h2>
                            <span className='mailing__cards-text'>
                                Получаем иоговую сумму с заказов и средний чек
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
            {createPortal(
                <RequestModal active={activeModal} onClose={onCloseModal} />,
                document.body
            )}
        </section>
    );
};
