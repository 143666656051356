import headerLogo from '../../assets/images/we-do.svg';
import imageCard1 from '../../assets/images/we-do-card-1.svg';
import imageCard2 from '../../assets/images/we-do-card-2.png';
import vk from '../../assets/images/vk-card.png';
import viber from '../../assets/images/viber-card.png';
import telegram from '../../assets/images/telegram-card.png';
import app from '../../assets/images/app-card.png';
import { Card } from '../card/card';
import './weDoSection.scss';

export const WeDoSection = () => {
    return (
        <section className='we-do'>
            <div className='__wrapper'>
                <div className='we-do__wrapper'>
                    <span className='we-do__header'>
                        <h1 className='we-do__header-text'>Мы делаем</h1>
                        <img
                            src={headerLogo}
                            alt='Мы делаем'
                            className='we-do__header-image'
                        />
                    </span>
                    <div className='we-do__content'>
                        <Card className='we-do__card we-do__card--one'>
                            <div className='we-do__card-header'>
                                <h2 className='we-do__card-title'>01</h2>
                                <h3 className='we-do__card-subtitle'>
                                    Каналы привлечения гостей в лояльность
                                </h3>
                            </div>
                            <ul>
                                <li className='we-do__card-text'>
                                    Кнопка или ссылка в социальной сети или
                                    рекламе в интернете
                                </li>
                                <li className='we-do__card-text'>
                                    SMS от ресторана со ссылкой
                                </li>
                                <li className='we-do__card-text'>
                                    QR-код на столе, на чеке, в меню, на
                                    листовке
                                </li>
                            </ul>
                            <img
                                src={imageCard1}
                                className='we-do__card-image'
                                alt='Картинка с людьми'
                            />
                        </Card>

                        <Card className='card we-do__card--two'>
                            <img
                                src={imageCard2}
                                className='we-do__card-image'
                                alt='Изображение телефона'
                            />
                            <div>
                                <div className='we-do__card-header'>
                                    <h2 className='we-do__card-title'>02</h2>
                                    <h3 className='we-do__card-subtitle'>
                                        Диалог в популярном мессенджере или
                                        приложении
                                    </h3>
                                </div>
                                <div className='we-do__card-group-text'>
                                    <div>
                                        <div className='we-do__card-group-app'>
                                            <img
                                                src={telegram}
                                                alt='Телеграм'
                                            />
                                            <span className='we-do__card-text'>
                                                Телеграм
                                            </span>
                                        </div>
                                        <div className='we-do__card-group-app'>
                                            <img src={vk} alt='Вконтакте' />
                                            <span className='we-do__card-text'>
                                                Вконтакте
                                            </span>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='we-do__card-group-app'>
                                            <img src={viber} alt='Viber' />
                                            <span className='we-do__card-text'>
                                                Viber
                                            </span>
                                        </div>
                                        <div className='we-do__card-group-app'>
                                            <img src={app} alt='Приложение' />
                                            <span className='we-do__card-text'>
                                                Приложение
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card>
                        <Card className='we-do__card--three'>
                            <div className='we-do__card-header'>
                                <h2 className='we-do__card-title'>03</h2>
                                <h3 className='we-do__card-subtitle'>
                                    Все необходимое всегда под рукой!
                                </h3>
                            </div>
                            <div className='we-do__card-group-text'>
                                <ul>
                                    <li className='we-do__card-text'>
                                        Вызвать электронную карту
                                    </li>
                                    <li className='we-do__card-text'>
                                        Получать рассылки и персональные
                                        предложения
                                    </li>
                                    <li className='we-do__card-text'>
                                        Оставить отзыв на заказ в доставке и в
                                        зале
                                    </li>
                                </ul>
                                <ul>
                                    <li className='we-do__card-text'>
                                        Сделать и оплатить заказ
                                    </li>
                                    <li className='we-do__card-text'>
                                        Узнать статус заказа и информацию о
                                        бонусах
                                    </li>
                                    <li className='we-do__card-text'>
                                        Поговорить в чате с живым сотрудником
                                        ресторана
                                    </li>
                                </ul>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
        </section>
    );
};
