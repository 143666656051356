import { FC, useState } from 'react';
import image from '../../assets/images/modal-image.svg';
import { Button } from '../button/button';
import { Modal } from '../modal/modal';
import { Field, Formik, FormikHelpers, Form } from 'formik';
import { Input } from '../input/input';
import ReactLoading from 'react-loading';
import axios from 'axios';
import person from '../../assets/images/input/person.svg';
import phone from '../../assets/images/input/phone.svg';
import email from '../../assets/images/input/mail.svg';
import logo from '../../assets/images/white-logo.svg';
import { requestFormSchema } from './validationRequest';
import { TERMS_OF_USE } from '../../utils/consts/consts';

import './requestModal.scss';
import { useMediaQuery } from '@mui/material';

interface IRequestModal {
    /** Видимость модального окна. */
    active: boolean;

    /** Ф-я закрытия модального окна. */
    onClose(): void;
}

interface IValues {
    name: string;
    email: string;
    phone: string;
}

enum Row {
    Name = 'Имя',
    Email = 'E-mail',
    Phone = 'Номер телефона',
}

export const RequestModal: FC<IRequestModal> = (props) => {
    const { active, onClose } = props;
    const isMobile = useMediaQuery('(max-width:540px)');
    const [successful, setSuccessful] = useState(false);

    const initialValues: IValues = {
        name: '',
        email: '',
        phone: '',
    };

    const onSubmit = (
        values: IValues,
        { resetForm, setSubmitting }: FormikHelpers<IValues>
    ) => {
        axios
            .post(
                'https://sheet.best/api/sheets/0c19b002-096f-4c87-948e-e9650fda9650',
                {
                    [Row.Name]: values.name,
                    [Row.Email]: values.email,
                    [Row.Phone]: `'${values.phone}`,
                }
            )
            .then(() => {
                resetForm();
                setSubmitting(false);
                setSuccessful(true);
            });
        resetForm();
        setSubmitting(false);
        setSuccessful(true);
    };

    return (
        <Modal active={active} onClose={onClose} className='request-modal'>
            <div className='request-modal__content'>
                <div className='request-modal__wrapper-image'>
                    <img
                        src={logo}
                        alt='Логотип продукта'
                        className='request-modal__logo'
                    />
                    <img
                        src={image}
                        alt='Изображение людей'
                        className='request-modal__image'
                    />
                </div>
                {!successful ? (
                    <div className='request-modal__group-content'>
                        <div className='request-modal__title'>
                            Давайте обсудим проект
                        </div>
                        <div className='request-modal__group-form'>
                            <Formik
                                initialValues={initialValues}
                                validationSchema={requestFormSchema}
                                onSubmit={(values, actions) =>
                                    onSubmit(values, actions)
                                }>
                                {({ isSubmitting }) => (
                                    <Form>
                                        <Field
                                            name='name'
                                            component={Input}
                                            icon={person}
                                            placeholder='Имя'
                                            className='request-modal__input'
                                        />
                                        <Field
                                            name='email'
                                            component={Input}
                                            icon={email}
                                            placeholder='E-mail'
                                            className='request-modal__input'
                                        />
                                        <Field
                                            name='phone'
                                            component={Input}
                                            icon={phone}
                                            placeholder='Номер телефона'
                                            type='tel'
                                            className='request-modal__input'
                                        />
                                        {isSubmitting && (
                                            <ReactLoading
                                                type='bubbles'
                                                color='#C0E709'
                                                className='request-modal__loading'
                                            />
                                        )}
                                        <Button
                                            theme='green'
                                            className='request-modal__button'
                                            type='submit'
                                            disabled={isSubmitting}>
                                            Oтправить заявку
                                        </Button>

                                        <div className='request-modal__small-text'>
                                            Отправляя заявку я соглашаюсь
                                            с&nbsp;
                                            <a
                                                href={TERMS_OF_USE}
                                                rel='noreferrer'
                                                target='_blank'>
                                                Пользовательским соглашением
                                            </a>
                                        </div>

                                        {!isMobile && (
                                            <div className='request-modal__small-text'>
                                                Все поля обязательны для
                                                заполнения
                                            </div>
                                        )}
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                ) : (
                    <div className='success'>
                        <div className='request-modal__title'>
                            Заявка успешно отправлена
                        </div>
                        <div className='request-modal__text'>
                            Спасибо, наш менеджер в ближайшее время свяжется
                            с вами для обсуждения проекта
                        </div>
                    </div>
                )}
            </div>
        </Modal>
    );
};
