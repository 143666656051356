import { clientsIcons, smallClientsIcons } from '../../utils/lists/clients';
import useMediaQuery from '@mui/material/useMediaQuery';
// @ts-ignore
import Marquee from 'react-double-marquee';
import './clients.scss';

export const Clients = () => {
    const isMobile = useMediaQuery('(max-width:800px)');

    return (
        <section className='clients'>
            {!isMobile && (
                <Marquee direction='left' speed='0.15'>
                    {clientsIcons.map((partner) => (
                        <img
                            key={partner.alt}
                            src={partner.image}
                            alt={partner.alt}
                        />
                    ))}
                </Marquee>
            )}

            {isMobile && (
                <div className='clients__wrapper'>
                    {smallClientsIcons.map((partner) => (
                        <img
                            key={partner.alt}
                            src={partner.image}
                            alt={partner.alt}
                        />
                    ))}
                </div>
            )}
        </section>
    );
};
