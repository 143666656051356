import logo from '../../assets/images/logo.svg';
import { Button } from '../button/button';
import useMediaQuery from '@mui/material/useMediaQuery';
import './header.scss';
import { HamburgerMenu } from '../hamburgerMenu/hamburgerMenu';
import { FC, useState } from 'react';
import { RequestModal } from '../requestModal/requestModal';
import { createPortal } from 'react-dom';
import clsx from 'clsx';

interface IHeaderProps {
    /** Является ли заголовок закрепленным. */
    isFixed?: boolean;
}

export const Header: FC<IHeaderProps> = (props) => {
    const { isFixed = false } = props;
    const isMobile = useMediaQuery('(max-width:800px)');
    const [activeModal, setActiveModal] = useState(false);

    const onOpenModal = () => {
        setActiveModal(true);
    };

    const onCloseModal = () => {
        setActiveModal(false);
    };

    if (activeModal) {
        requestAnimationFrame(() => (document.body.style.overflow = 'hidden'));
    } else {
        document.body.style.overflow = 'unset';
    }

    return (
        <header className={clsx('header', isFixed && 'header--fixed')}>
            <div className='header__container'>
                {isMobile && (
                    <div className='header__group'>
                        <HamburgerMenu>
                            <li className='menu__item'>
                                <a href='#2' className='menu__link'>
                                    Мы делаем
                                </a>
                            </li>
                            <li className='menu__item'>
                                <a href='#8' className='menu__link'>
                                    Тарифы
                                </a>
                            </li>
                            <li className='menu__item'>
                                <a href='#4' className='menu__link'>
                                    Кейсы
                                </a>
                            </li>
                        </HamburgerMenu>

                        <a href='#1'>
                            <img
                                src={logo}
                                alt='Логотип'
                                className='header__image'
                            />
                        </a>
                    </div>
                )}

                {!isMobile && (
                    <>
                        <a href='#1'>
                            <img
                                src={logo}
                                alt='Логотип'
                                className='header__image'
                            />
                        </a>

                        <nav className='header__menu menu'>
                            <ul className='menu__list'>
                                <li className='menu__item'>
                                    <a href='#2' className='menu__link'>
                                        Мы делаем
                                    </a>
                                </li>
                                <li className='menu__item'>
                                    <a href='#8' className='menu__link'>
                                        Тарифы
                                    </a>
                                </li>
                                <li className='menu__item'>
                                    <a href='#4' className='menu__link'>
                                        Кейсы
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </>
                )}

                <Button
                    theme='transparent-green'
                    className='header__button'
                    onClick={onOpenModal}>
                    Обсудить проект
                </Button>
            </div>
            {createPortal(
                <RequestModal active={activeModal} onClose={onCloseModal} />,
                document.body
            )}
        </header>
    );
};
